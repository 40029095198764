import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <iframe src="https://docs.google.com/document/d/e/2PACX-1vRg7DIMaAIGgCCaNsl5ZROGvRZWstjrR5eFlzPChRdouhNEfIlEkOZtTNTtkRZ_UQpCyNO6JAV7POji/pub?embedded=true"></iframe>
    </div>
  );
}

export default App;
